// import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
// import { useRecoilValue } from 'recoil';
// import { useFormik } from 'formik';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog';
// import { Accordion, AccordionTab } from 'primereact/accordion';
// import { authAtom } from './../_state/auth';
// import { useUserActions } from './../_actions/user.actions';
// import { SigninDialog } from './SigninDialog';
// import { SignupDialog } from './SignupDialog';

// import {
//   IconMessageShare,
//   IconHeadphones,
//   IconBraille,
//   IconMessageForward,
// } from '@tabler/icons-react';
// import './LandingPage.css';
// import './Landing.css';

// // Utility function to validate form data
// const validateFormData = (data) => {
//   const errors = {};

//   if (!data.email) {
//     errors.email = 'Email is required.';
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
//     errors.email = 'Invalid email address. E.g. example@email.com';
//   }

//   if (!data.password) {
//     errors.password = 'Password is required.';
//   }

//   return errors;
// };

// // Memoized Banner component to avoid re-render
// const Banner = React.memo(() => (
//   <div className="banner">
//     <img src="../Banner.jpeg" alt="Banner" className="banner-image" />
//   </div>
// ));

// // Menu component, extracted for better readability and reusability
// const Menu = ({ onLoginClick, onRegisterClick, handleGiveClick, handleTeensMaterialsClick, handleTeevoAudioClick, handleBrailleClick }) => (
//   <div className="grid p-fluid">
//     <div className="col-12 md:col-4">
//       <div className="flex align-items-center justify-content-center">
//         <Button
//           icon="pi pi-user"
//           label="Login to Download Teevo"
//           className="btn-login mr-2"
//           rounded
//           text
//           raised
//           aria-label="Login"
//           onClick={onLoginClick}
//         />
//         <Button
//           icon="pi pi-plus"
//           label="Sign Up to Download Teevo"
//           className="btn-register"
//           rounded
//           text
//           raised
//           aria-label="Register"
//           onClick={onRegisterClick}
//         />
//       </div>
//     </div>

//     <iframe
//       width="100%"
//       height="270px"
//       src="https://teevo-audio.b-cdn.net/videos/Teevo_September.mp4"
//       title="YouTube video player"
//       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//       allowFullScreen
//     ></iframe>

//     <div className="col-12 md:col-6 accordion-header-1">
//       <Accordion activeIndex={[0]} className='accordion-content'>
//         <AccordionTab header={<><IconMessageShare stroke={2} className="mr-2" /> Sponsor a TeeVo Translation</>}>
//           <div className="flex align-items-start">
//             <div className="accordion-content">
//               <p>Spread the message of TeeVo by making copies available to teenagers in all known human languages...</p>
//               <Button className="btn-sponsor" label="Sponsor Now" icon="pi pi-external-link" onClick={handleGiveClick} />
//             </div>
//           </div>
//         </AccordionTab>
//       </Accordion>
//     </div>

//     <div className="col-12 md:col-6 accordion-header-2">
//       <Accordion activeIndex={[1]} className='accordion-content'>
//         <AccordionTab header={<><IconHeadphones stroke={2} className="mr-2" />Listen to TeeVo Audio Format</>}>
//           <div className="flex align-items-start">
//             <div className="accordion-content">
//               <p>Enjoy the inspiring and thought-provoking content of TeeVo in a convenient audio format...</p>
//               <Button
//                 className="btn-listen"
//                 rounded
//                 text
//                 label="Listen To Today’s Teevo Article."
//                 raised
//                 icon="pi pi-share-alt"
//                 onClick={handleTeevoAudioClick}
//               />
//             </div>
//           </div>
//         </AccordionTab>
//       </Accordion>
//     </div>

//     <div className="col-12 md:col-6 accordion-header-3">
//       <Accordion activeIndex={[2]} className='accordion-content'>
//         <AccordionTab header={<><IconBraille stroke={2} className="mr-2 accordion-content" /> Request for Braille format</>}>
//           <div className="flex align-items-start">
//             <div className="accordion-content">
//               <p>Rhapsody TeeVo is now available in Braille format...</p>
//               <Button
//                 className="btn-braille"
//                 rounded
//                 text
//                 icon="pi pi-ellipsis-h"
//                 raised label=" Request for Braille format"
//                 onClick={handleBrailleClick}
//               />
//             </div>
//           </div>
//         </AccordionTab>
//       </Accordion>
//     </div>

//     <div className="col-12 md:col-6 accordion-header-4">
//       <Accordion activeIndex={[3]} className='accordion-content'>
//         <AccordionTab header={<><IconMessageForward stroke={2} className="mr-2" /> Get other Teens Publications</>}>
//           <div className="flex align-items-start">
//             <div className="accordion-content">
//               <p>Explore our collection of teen-focused publications designed to inspire...</p>
//               <Button
//                 className="btn-teens"
//                 rounded
//                 text
//                 icon="pi pi-book"
//                 label=" Get other Teens Publications"
//                 raised
//                 onClick={handleTeensMaterialsClick}
//               />
//             </div>
//           </div>
//         </AccordionTab>
//       </Accordion>
//     </div>
//   </div>
// );

// export const LandingPage = () => {
//   const userActions = useUserActions();
//   const msgError = useRef(null);
//   const [isAuthenticated, setAuthenticated] = useState(false);
//   const auth = useRecoilValue(authAtom);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const from = location.state?.from?.pathname || "/";
//   const [isSignupDialogVisible, setSignupDialogVisible] = useState(false);
//   const [showSigninDialog, setShowSigninDialog] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: ''
//     },
//     validate: validateFormData,
//     onSubmit: (data) => {
//       userActions.login(data.email, data.password)
//         .then(() => {
//           formik.resetForm();
//           navigate(from, { replace: true });
//         })
//         .catch((err) => {
//           console.error('Login error:', err);
//           if (msgError.current) {
//             msgError.current.show([{ severity: 'error', detail: err.message || 'Login failed', sticky: false, life: 5000 }]);
//           }
//         });
//     }
//   });

//   useEffect(() => {
//     if (auth?.role === 'Admin') {
//       setAuthenticated(true);
//     }
//   }, [auth?.role]);

//   const handleSignOutClick = useCallback(() => {
//     userActions.logout();
//     navigate("/en/dist");
//   }, [navigate, userActions]);

//   const handleLoginClick = useCallback(() => {
//     setShowSigninDialog(true);
//   }, []);

//   const handleRegisterClick = useCallback(() => {
//     setSignupDialogVisible(true);
//   }, []);

//   const handleLoginSuccess = useCallback(() => {
//     setSignupDialogVisible(false);
//   }, []);

//   const handleGiveClick = useCallback(() => {
//     window.open("https://give.rhapsodyofrealities.org/", "_blank");
//   }, []);

//   const handleTeensMaterialsClick = useCallback(() => {
//     window.open("https://www.teevotogo.org", "_blank");
//   }, []);

//   const handleTeevoAudioClick = useCallback(() => {
//     window.open("https://www.teevotogo.org/audio/", "_blank");
//   }, []);

//   const handleBrailleClick = useCallback(() => {
//     window.open("https://www.teevotogo.org/contact-us/", "_blank");
//   }, []);

//   const bannerStyle = {
//     position: 'relative',
//     width: '100%',
//     height: '400px',
//     overflow: 'hidden',
//   };

//   const landingPageStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     color: '#fff',
//     textAlign: 'center',
//     zIndex: 2,
//     width: '100%',
//   };


//   const hideSignupDialog = () => {
//     setSignupDialogVisible(false);
//   };


//   return (
//     <>
//       {!auth && <Banner />}

//       <div className="page-content">
//         {!isAuthenticated && (
//           <div style={bannerStyle}>
//             <div style={landingPageStyle}>
//               <Button label="Sign Out" onClick={handleSignOutClick} />
//             </div>
//           </div>
//         )}

//         <Menu
//           onLoginClick={handleLoginClick}
//           onRegisterClick={handleRegisterClick}
//           handleGiveClick={handleGiveClick}
//           handleTeensMaterialsClick={handleTeensMaterialsClick}
//           handleTeevoAudioClick={handleTeevoAudioClick}
//           handleBrailleClick={handleBrailleClick}
//         />

//         <Dialog visible={showSigninDialog} onHide={() => setShowSigninDialog(false)}>
//           <SigninDialog />
//         </Dialog>

//         <Dialog visible={isSignupDialogVisible} onHide={() => setSignupDialogVisible(false)}>
//           <SignupDialog onSignupSuccess={handleLoginSuccess} />
//         </Dialog>

//       </div>
//     </>
//   );
// };



import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { authAtom } from './../_state/auth';
import { useUserActions } from './../_actions/user.actions';
import { SigninDialog } from './SigninDialog';
import { SignupDialog } from './SignupDialog';
import { DownloadPage } from './DownloadPage';
import './LandingPage.css';
import './Landing.css';
import { IconMessageShare, IconHeadphones, IconBraille, IconMessageForward } from '@tabler/icons-react';
import { Accordion, AccordionTab } from 'primereact/accordion';

export const LandingPage = () => {
  const userActions = useUserActions();
  const msgError = useRef(null);
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isSignupDialogVisible, setSignupDialogVisible] = useState(false);

  const [showSigninDialog, setShowSigninDialog] = useState(false);

  useEffect(() => {
    if (auth?.role === 'Admin') {
      setAuthenticated(true);
    }
  }, [auth?.role]);


  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validate: (data) => {
      const errors: { email?: string; password?: string } = {};

      if (!data.email) {
        errors.email = 'Email is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = 'Invalid email address. E.g. example@email.com';
      }

      if (!data.password) {
        errors.password = 'Password is required.';
      }

      return errors;
    },
    onSubmit: (data) => {
      setLoading(true);
      userActions.login(data.email, data.password)
        .then(() => {
          setLoading(false);
          formik.resetForm();
          navigate(from, { replace: true });
        })
        .catch((err) => {
          setLoading(false);
          console.error('Login error:', err);
          if (msgError.current) {
            msgError.current.show([{ severity: 'error', detail: err.message || 'Login failed', sticky: false, life: 5000 }]);
          }
        });
    }
  });


  const handleTeensMaterialsClick = useCallback(() => {
    window.open("https://www.teevotogo.org", "_blank");
  }, []);

  const handleTeevoAudioClick = useCallback(() => {
    window.open("https://www.teevotogo.org/audio/", "_blank");
  }, []);

  const handleGiveClick = useCallback(() => {
    window.open("https://give.rhapsodyofrealities.org/", "_blank");
  }, []);

  const handleBrailleClick = useCallback(() => {
    window.open("https://www.teevotogo.org/contact-us/", "_blank");
  }, []);



  const hideSignupDialog = () => {
    setSignupDialogVisible(false);
  };


  const handleLoginClick = useCallback(() => {
    setShowSigninDialog(true);
  }, []);


  const handleRegisterClick = useCallback(() => {
    setSignupDialogVisible(true)
  }, []);



  const loadMenu = () => (
    <div className="grid p-fluid">
      <iframe
        width="100%"
        height="270px"
        src="https://teevo-audio.b-cdn.net/videos/Teevo_September.mp4"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>


      <div className="col-12 md:col-4">
        <div className="flex align-items-center justify-content-center">
          <Button
            icon="pi pi-user"
            label="Login to Download Teevo"
            className="btn-login mr-2"
            rounded
            text
            raised
            aria-label="Login"
            onClick={handleLoginClick}
          />
          <Button
            icon="pi pi-plus"
            label="Sign Up to Download Teevo"
            className="btn-register"
            rounded
            text
            raised
            aria-label="Register"
            onClick={handleRegisterClick}
          />
        </div>
      </div>

      <div className="col-12 md:col-6 accordion-header-1">
        <Accordion activeIndex={[0]} className='accordion-content'>
          <AccordionTab header={<><IconMessageShare stroke={2} className="mr-2" /> Sponsor a TeeVo Translation</>}>
            <div className="flex align-items-start">
              <div className="accordion-content">
                <p>Spread the message of TeeVo by making copies available to teenagers in all known human languages as you sponsor a language translation, sponsor street distribution, and sponsor the ReachOut World Day.</p>
                <Button className="btn-sponsor" label="Sponsor Now" icon="pi pi-external-link" onClick={handleGiveClick} />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>

      <div className="col-12 md:col-6 accordion-header-2">
        <Accordion activeIndex={[1]} className='accordion-content'>
          <AccordionTab header={<><IconHeadphones stroke={2} className="mr-2" />Listen to TeeVo Audio Format</>}>
            <div className="flex align-items-start">
              <div className="accordion-content">
                <p>Enjoy the inspiring and thought-provoking content of TeeVo in a convenient audio format. Whether you're commuting, working out, or relaxing, you can now listen to TeeVo's life-changing messages anytime, anywhere. Stay connected to God's Word and grow in your faith on the go.</p>


                <Button
                  className="btn-listen"
                  rounded
                  text
                  label="Listen To Today’s Teevo Article."
                  raised
                  icon="pi pi-share-alt"
                  onClick={handleTeevoAudioClick}
                >

                </Button>

              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>

      <div className="col-12 md:col-6 accordion-header-3">
        <Accordion activeIndex={[2]} className='accordion-content'>
          <AccordionTab header={<><IconBraille stroke={2} className="mr-2 accordion-content" /> Request for Braille format</>}>
            <div className="flex align-items-start">
              <div className="accordion-content">
                <p> Rhapsody TeeVo is now available in Braille format, making it accessible to visually impaired teens. Everyone deserves access to God's Word. And Together, we can ensure that every teen has the opportunity to engage with TeeVo's inspiring content and grow in their relationship with God. If someone you know would benefit from the Braille version, please submit a request today!.</p>
                <Button
                  className="btn-braille"
                  rounded
                  text
                  icon="pi pi-ellipsis-h"
                  raised label=" Request for Braille format"
                  onClick={handleBrailleClick}
                >

                </Button>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>

      <div className="col-12 md:col-6 accordion-header-4">
        <Accordion activeIndex={[3]} className='accordion-content'>
          <AccordionTab header={<><IconMessageForward stroke={2} className="mr-2" /> Get other Teens Publications</>}>
            <div className="flex align-items-start">
              <div className="accordion-content">
                <p> Explore our collection of teen-focused publications designed to inspire, encourage, and help you discover your true purpose by providing you answers from the perspective of God's Word. From our teens devotional and teens reference Bible to inspirational stories and practical guides, guaranteed to help you enjoy an exciting ride with God's Word.</p>
                <Button
                  className="btn-teens"
                  rounded
                  text
                  icon="pi pi-book"
                  label=" Get other Teens Publications"
                  raised
                  onClick={handleTeensMaterialsClick}
                >


                </Button>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>


    </div>
  );


  const handleLoginSuccess = () => {
    setSignupDialogVisible(false);
  };






  return (
    <>
      {/* Show banner only if user is not authenticated */}
      {/* {!auth && (
        <div className="banner">
          <img src="../Banner.jpeg" alt="Banner" className="banner-image" />
        </div>
      )} */}



      <div className="banner">
        <img src="../Banner.jpg" alt="Banner" className="banner-image" />
      </div>


      <div id="landPage" className="bg-primary-white position-relative overflow-hidden">
        <div className="container">
          <div className="row justify-content-center align-items-lg-center">
            <div className="col-md-8 col-lg-6">
              <div className="ps-lg-5">
                <div className="card card-lg rounded-lg">
                  <div className="card-body">
                    <div className="row align-items-sm-center"></div>
                    {auth ? <DownloadPage /> : loadMenu()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {showSigninDialog && (
        <SigninDialog
          onClose={() => setShowSigninDialog(false)}
          onLoginSuccess={handleLoginSuccess}
        />
      )}

      <Dialog
        header="Register"
        visible={isSignupDialogVisible}
        style={{
          width: window.innerWidth > 768 ? '40vw' : '90vw',
        }}
        onHide={hideSignupDialog}
        dismissableMask
      >
        <SignupDialog onLoginSuccess={handleLoginSuccess} />
      </Dialog>

      <footer className="bg-primary-dark border-top border-white-10 mt-auto">
        <div className="container">
          <div className="border-top border-white-10" />
          <div className="col-md mb-3 mb-md-0">
            <p className="text-white mb-0 text-center">© {new Date().getFullYear()} LoveWorld Teens Publishing. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}