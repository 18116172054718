import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'primereact/button';
import { FileService } from '../_services/FileService';
import { authAtom } from '../_state/auth';
import { useRecoilValue } from 'recoil';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { useUserActions } from '../_actions/user.actions';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

export const DownloadPage = () => {
    const auth = useRecoilValue(authAtom);
    const toast = useRef(null);
    const fileService = useRef(new FileService());
    const navigate = useNavigate();
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [yearOptions, setYearOptions] = useState([]);
    const userActions = useUserActions();
    const [selectedTeeVo, setSelectedTeeVo] = useState(null);
    const [teeVoList, setTeeVoList] = useState([]);

    const months = [
        { name: 'January', value: 'January' },
        { name: 'February', value: 'February' },
        { name: 'March', value: 'March' },
        { name: 'April', value: 'April' },
        { name: 'May', value: 'May' },
        { name: 'June', value: 'June' },
        { name: 'July', value: 'July' },
        { name: 'August', value: 'August' },
        { name: 'September', value: 'September' },
        { name: 'October', value: 'October' },
        { name: 'November', value: 'November' },
        { name: 'December', value: 'December' }
    ];

    const handleError = useCallback((error) => {
        setShowSpinner(false);
        console.error("Error:", error);
        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
    }, []);


    const getLanguages = useCallback(async (year, month) => {
        setShowSpinner(true);
        try {
            const res = await fileService.current.getTeeVoLanguagesByBookName('TeeVo', month, year);
            const sortedData = res.data.sort((a, b) => a.book_language.localeCompare(b.book_language));
            setTeeVoList(sortedData);
        } catch (error) {
            handleError(error);
        } finally {
            setShowSpinner(false);
        }
    }, [handleError]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: currentYear - 2022 + 1 }, (_, index) => (currentYear - index).toString());
        setYearOptions(years.map(year => ({ name: year, value: year })));

        const month = new Date().toLocaleString("en-US", { month: "long" });
        setSelectedMonth(month);
        setSelectedYear(currentYear.toString());

        getLanguages(currentYear.toString(), month);
    }, [getLanguages]);

    const onYearChange = useCallback((e) => {
        setSelectedYear(e.value);
        getLanguages(e.value, selectedMonth);
    }, [selectedMonth, getLanguages]);

    const onMonthChange = useCallback((e) => {
        setSelectedMonth(e.value);
        getLanguages(selectedYear, e.value);
    }, [selectedYear, getLanguages]);

    const onTeeVoChange = useCallback((e) => {
        setSelectedTeeVo(e.value);
    }, []);

    const onDownloadTeeVoClick = useCallback(async () => {
        if (!selectedYear || !selectedMonth || !selectedTeeVo) {
            handleError({ message: 'Please select a year, month, and language' });
            return;
        }
        setShowSpinner(true);
        await DownloadTeevo(selectedTeeVo.book_language, 'TeeVo', selectedMonth, selectedYear);
    }, [selectedYear, selectedMonth, selectedTeeVo]);

    const handleSignOutClick = useCallback(() => {
        userActions.logout();
        navigate("/en/dist");
    }, [userActions, navigate]);

    const handleHomePageClick = useCallback(() => {
        userActions.logout();
        window.location.href = "https://www.teevotogo.org/";
    }, [userActions]);

    const downloadFile = useCallback(async (id, path, mimetype) => {
        try {
            await fileService.current.downloadFile(id, path, mimetype);
            await fileService.current.downloadTransaction(auth.id, id);
            await fileService.current.countDownload(auth.id, id);
            setShowSpinner(false);
        } catch (error) {
            setShowSpinner(false);
            handleError(error);
        }
    }, [auth.id]);

    const DownloadTeevo = useCallback(async (language, book, month, year) => {
        try {
            const response = await fileService.current.DownloadTeevo(language, book, month, year);
            if (!response.data || response.data.length === 0) {
                handleError({ message: 'The selected edition does not exist.' });
            } else {
                const { _id: fileid, file_path: filePath, file_mime_type: fileMimeType } = response.data[0];
                await downloadFile(fileid, filePath, fileMimeType);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setShowSpinner(false);
        }
    }, [downloadFile]);


    return (
        <>
            {showSpinner && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    zIndex: 9999
                }}>
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} />
            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div style={{ fontSize: '18px' }}>Welcome <strong>{auth?.email}</strong></div>
                <div className="flex align-items-center text-700 flex-wrap">
                    <div className="mr-2 flex align-items-center mt-2" id="instructions" style={{ fontSize: '15px' }}>
                        <span><em>If you wish to download previous editions, kindly select the year and month of the desired edition</em></span>
                    </div>
                </div>
                <div className="col-12 xl:col-12">
                    <div className="grid p-fluid">
                        <div className="col-8 md:col-4">
                            <br /><br />
                        </div>
                        <div className="grid p-fluid" id="passMonth">
                            <div className="col-8 md:col-4">
                                <div className="p-inputgroup">
                                    <Dropdown value={selectedYear} options={yearOptions} onChange={onYearChange} optionLabel="name" placeholder="Select Year" />
                                </div>
                            </div>
                            <div className="col-8 md:col-4">
                                <div className="p-inputgroup">
                                    <Dropdown value={selectedMonth} options={months} onChange={onMonthChange} optionLabel="name" placeholder="Select Month" />
                                </div>
                            </div>
                            <div className="col-8 md:col-4">
                                <div className="p-inputgroup">
                                    <Dropdown value={selectedTeeVo} options={teeVoList} onChange={onTeeVoChange} optionLabel="book_language" placeholder="Select Language" />
                                    <Button icon="pi pi-download" rounded aria-label="Filter" onClick={onDownloadTeeVoClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex align-items-start">
                <Button rounded text raised severity="warning" className="flex align-items-center" aria-label="Notification" onClick={handleHomePageClick}
                    style={{ width: '200px', justifyContent: 'center' }}>
                    Return to homepage
                </Button>
                <Button rounded text raised severity="warning" className="flex align-items-center ml-2" aria-label="User" onClick={handleSignOutClick}
                    style={{ width: '200px', justifyContent: 'center' }}>
                    Logout
                </Button>
            </div>
        </>
    );
};


// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { Button } from 'primereact/button';
// import { FileService } from '../_services/FileService';
// import { authAtom } from '../_state/auth';
// import { useRecoilValue } from 'recoil';
// import { Dropdown } from 'primereact/dropdown';
// import { useNavigate } from 'react-router-dom';
// import { useUserActions } from '../_actions/user.actions';
// import { ProgressSpinner } from 'primereact/progressspinner';
// import { Toast } from 'primereact/toast';

// export const DownloadPage = () => {
//     const auth = useRecoilValue(authAtom);
//     const toast = useRef(null); // Initialize the toast ref
//     const fileService = new FileService();
//     const navigate = useNavigate();
//     const [selectedMonth, setSelectedMonth] = useState('');
//     const [selectedYear, setSelectedYear] = useState('');
//     const [showSpinner, setShowSpinner] = useState(false);
//     const [yearOptions, setYearOptions] = useState([]);
//     const userActions = useUserActions();
//     const [selectedTeeVo, setSelectedTeeVo] = useState(null);
//     const [teeVoList, setTeeVoList] = useState([]);

//     const months = [
//         { name: 'January', value: 'January' },
//         { name: 'February', value: 'February' },
//         { name: 'March', value: 'March' },
//         { name: 'April', value: 'April' },
//         { name: 'May', value: 'May' },
//         { name: 'June', value: 'June' },
//         { name: 'July', value: 'July' },
//         { name: 'August', value: 'August' },
//         { name: 'September', value: 'September' },
//         { name: 'October', value: 'October' },
//         { name: 'November', value: 'November' },
//         { name: 'December', value: 'December' }
//     ];

//     const getLanguages = async (year, month) => {
//         setShowSpinner(true);
//         try {
//             const res = await fileService.getTeeVoLanguagesByBookName('TeeVo', month, year);
//             const sortedData = res.data.sort((a, b) => a.book_language.localeCompare(b.book_language));
//             setTeeVoList(sortedData);
//         } catch (error) {
//             handleError(error);
//         } finally {
//             setShowSpinner(false);
//         }
//     };

//     useEffect(() => {
//         const currentYear = new Date().getFullYear();
//         const years = Array.from({ length: currentYear - 2022 + 1 }, (_, index) => (currentYear - index).toString());
//         setYearOptions(years.map(year => ({ name: year, value: year })));

//         const month = new Date().toLocaleString("en-US", { month: "long" });
//         setSelectedMonth(month);
//         setSelectedYear(currentYear.toString());

//         getLanguages(currentYear, month);
//     }, [getLanguages]);

//     const onYearChange = (e) => {
//         setSelectedYear(e.value);
//         getLanguages(e.value, selectedMonth);
//     };

//     const onMonthChange = (e) => {
//         setSelectedMonth(e.value);
//         getLanguages(selectedYear, e.value);
//     };

//     const onTeeVoChange = (e) => {
//         setSelectedTeeVo(e.value);
//     };

//     const onDownloadTeeVoClick = useCallback(async () => {
//         if (!selectedYear || !selectedMonth || !selectedTeeVo) {
//             handleError({ message: 'Please select a year, month, and language' });
//             return;
//         }
//         setShowSpinner(true);
//         await DownloadTeevo(selectedTeeVo.book_language, 'TeeVo', selectedMonth, selectedYear);
//     }, [selectedYear, selectedMonth, selectedTeeVo]);

//     const handleSignOutClick = useCallback(() => {
//         userActions.logout();
//         navigate("/en/dist");
//     }, [navigate]);

//     const handleHomePageClick = useCallback(() => {
//         userActions.logout();
//         window.location.href = "https://www.teevotogo.org/";
//     }, []);

//     const downloadFile = useCallback(async (id, path, mimetype) => {
//         try {
//             await fileService.downloadFile(id, path, mimetype);
//             await fileService.downloadTransaction(auth.id, id);
//             await fileService.countDownload(auth.id, id);
//             setShowSpinner(false);
//         } catch (error) {
//             setShowSpinner(false);
//             handleError(error);
//         }
//     }, [auth.id]);

//     const DownloadTeevo = useCallback(async (language, book, month, year) => {
//         try {
//             const response = await fileService.DownloadTeevo(language, book, month, year);
//             if (!response.data || response.data.length === 0) {
//                 handleError({ message: 'The selected edition does not exist.' });
//             } else {
//                 const { _id: fileid, file_path: filePath, file_mime_type: fileMimeType } = response.data[0];
//                 await downloadFile(fileid, filePath, fileMimeType);
//             }
//         } catch (error) {
//             handleError(error);
//         } finally {
//             setShowSpinner(false);
//         }
//     }, [downloadFile]);

//     const handleError = useCallback((error) => {
//         setShowSpinner(false);
//         console.log("  +++++++++ H A N D L E     E R R O R ++++++++++++++++++ ")
//         console.log(error);
//     }, []);

//     return (
//         <>
//             {showSpinner && (
//                 <div style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     position: 'fixed',
//                     top: 0,
//                     left: 0,
//                     width: '100%',
//                     height: '100vh',
//                     backgroundColor: 'rgba(255, 255, 255, 0.7)',
//                     zIndex: 9999
//                 }}>
//                     <ProgressSpinner />
//                 </div>
//             )}
//             <Toast ref={toast} /> {/* Ensure Toast is initialized */}
//             <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
//                 <div style={{ fontSize: '18px' }}>Welcome <strong>{auth?.email}</strong></div>
//                 <div className="flex align-items-center text-700 flex-wrap">
//                     <div className="mr-2 flex align-items-center mt-2" id="instructions" style={{ fontSize: '15px' }}>
//                         <span><em>If you wish to download previous editions, kindly select the year and month of the desired edition</em></span>
//                     </div>
//                 </div>
//                 <div className="col-12 xl:col-12">
//                     <div className="grid p-fluid">
//                         <div className="col-8 md:col-4">
//                             <br /><br />
//                         </div>
//                         <div className="grid p-fluid" id="passMonth">
//                             <div className="col-8 md:col-4">
//                                 <div className="p-inputgroup">
//                                     <Dropdown value={selectedYear} options={yearOptions} onChange={onYearChange} optionLabel="name" placeholder="Select Year" />
//                                 </div>
//                             </div>
//                             <div className="col-8 md:col-4">
//                                 <div className="p-inputgroup">
//                                     <Dropdown value={selectedMonth} options={months} onChange={onMonthChange} optionLabel="name" placeholder="Select Month" />
//                                 </div>
//                             </div>
//                             <div className="col-8 md:col-4">
//                                 <div className="p-inputgroup">
//                                     <Dropdown value={selectedTeeVo} options={teeVoList} onChange={onTeeVoChange} optionLabel="book_language" placeholder="Select Language" />
//                                     <Button icon="pi pi-download" rounded aria-label="Filter" onClick={onDownloadTeeVoClick} />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="flex align-items-start">
//                 <Button rounded text raised severity="warning" className="flex align-items-center" aria-label="Notification" onClick={handleHomePageClick}
//                     style={{ width: '200px', justifyContent: 'center' }}>
//                     Return to homepage
//                 </Button>
//                 <Button rounded text raised severity="warning" className="flex align-items-center ml-2" aria-label="User" onClick={handleSignOutClick}
//                     style={{ width: '200px', justifyContent: 'center' }}>
//                     Logout
//                 </Button>
//             </div>
//         </>
//     );
// };



// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { Button } from 'primereact/button';
// import { FileService } from '../_services/FileService';
// import { authAtom } from '../_state/auth';
// import { useRecoilValue } from 'recoil';
// import { Dropdown } from 'primereact/dropdown';
// import { useNavigate } from 'react-router-dom';
// import { useUserActions } from '../_actions/user.actions';
// import { ProgressSpinner } from 'primereact/progressspinner';
// import { Toast } from 'primereact/toast';

// export const DownloadPage = () => {
//     const auth = useRecoilValue(authAtom);
//     const toast = useRef(null); // Initialize the toast ref
//     const fileService = new FileService();
//     const navigate = useNavigate();
//     const [selectedMonth, setSelectedMonth] = useState('');
//     const [selectedYear, setSelectedYear] = useState('');
//     const [showSpinner, setShowSpinner] = useState(false);
//     const [yearOptions, setYearOptions] = useState([]);
//     const userActions = useUserActions();
//     const [selectedTeeVo, setSelectedTeeVo] = useState(null);
//     const [teeVoList, setTeeVoList] = useState([]);

//     const months = [
//         { name: 'January', value: 'January' },
//         { name: 'February', value: 'February' },
//         { name: 'March', value: 'March' },
//         { name: 'April', value: 'April' },
//         { name: 'May', value: 'May' },
//         { name: 'June', value: 'June' },
//         { name: 'July', value: 'July' },
//         { name: 'August', value: 'August' },
//         { name: 'September', value: 'September' },
//         { name: 'October', value: 'October' },
//         { name: 'November', value: 'November' },
//         { name: 'December', value: 'December' }
//     ];

//     const getLanguages = async (year, month) => {
//         setShowSpinner(true);
//         try {
//             const res = await fileService.getTeeVoLanguagesByBookName('TeeVo', month, year);
//             const sortedData = res.data.sort((a, b) => a.book_language.localeCompare(b.book_language));
//             setTeeVoList(sortedData);
//         } catch (error) {
//             handleError(error);
//         } finally {
//             setShowSpinner(false);
//         }
//     };

//     useEffect(() => {
//         const currentYear = new Date().getFullYear();
//         const years = Array.from({ length: currentYear - 2022 + 1 }, (_, index) => (currentYear - index).toString());
//         setYearOptions(years.map(year => ({ name: year, value: year })));

//         const month = new Date().toLocaleString("en-US", { month: "long" });
//         setSelectedMonth(month);
//         setSelectedYear(currentYear.toString());

//         getLanguages(currentYear, month);
//     }, [getLanguages]);

//     const onYearChange = (e) => {
//         setSelectedYear(e.value);
//         getLanguages(e.value, selectedMonth);
//     };

//     const onMonthChange = (e) => {
//         setSelectedMonth(e.value);
//         getLanguages(selectedYear, e.value);
//     };

//     const onTeeVoChange = (e) => {
//         setSelectedTeeVo(e.value);
//     };

//     const onDownloadTeeVoClick = useCallback(async () => {
//         if (!selectedYear || !selectedMonth || !selectedTeeVo) {
//             handleError({ message: 'Please select a year, month, and language' });
//             return;
//         }
//         setShowSpinner(true);
//         await DownloadTeevo(selectedTeeVo.book_language, 'TeeVo', selectedMonth, selectedYear);
//     }, [selectedYear, selectedMonth, selectedTeeVo]);

//     const handleSignOutClick = useCallback(() => {
//         userActions.logout();
//         navigate("/en/dist");
//     }, [navigate]);

//     const handleHomePageClick = useCallback(() => {
//         userActions.logout();
//         window.location.href = "https://www.teevotogo.org/";
//     }, []);

//     const downloadFile = useCallback(async (id, path, mimetype) => {
//         try {
//             await fileService.downloadFile(id, path, mimetype);
//             await fileService.downloadTransaction(auth.id, id);
//             await fileService.countDownload(auth.id, id);
//             setShowSpinner(false);
//         } catch (error) {
//             setShowSpinner(false);
//             handleError(error);
//         }
//     }, [auth.id]);

//     const DownloadTeevo = useCallback(async (language, book, month, year) => {
//         try {
//             const response = await fileService.DownloadTeevo(language, book, month, year);
//             if (!response.data || response.data.length === 0) {
//                 handleError({ message: 'The selected edition does not exist.' });
//             } else {
//                 const { _id: fileid, file_path: filePath, file_mime_type: fileMimeType } = response.data[0];
//                 await downloadFile(fileid, filePath, fileMimeType);
//             }
//         } catch (error) {
//             handleError(error);
//         } finally {
//             setShowSpinner(false);
//         }
//     }, [downloadFile]);

//     const handleError = useCallback((error) => {
//         setShowSpinner(false);
//         console.log("  +++++++++ H A N D L E     E R R O R ++++++++++++++++++ ")
//         console.log(error);
//     }, []);

//     return (
//         <>
//             {showSpinner && <ProgressSpinner />}
//             <Toast ref={toast} /> {/* Ensure Toast is initialized */}
//             <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
//                 <div style={{ fontSize: '18px' }}>Welcome <strong>{auth?.email}</strong></div>
//                 <div className="flex align-items-center text-700 flex-wrap">
//                     <div className="mr-2 flex align-items-center mt-2" id="instructions" style={{ fontSize: '15px' }}>
//                         <span><em>If you wish to download previous editions, kindly select the year and month of the desired edition</em></span>
//                     </div>
//                 </div>
//                 <div className="col-12 xl:col-12">
//                     <div className="grid p-fluid">
//                         <div className="col-8 md:col-4">
//                             <br /><br />
//                         </div>
//                         <div className="grid p-fluid" id="passMonth">
//                             <div className="col-8 md:col-4">
//                                 <div className="p-inputgroup">
//                                     <Dropdown value={selectedYear} options={yearOptions} onChange={onYearChange} optionLabel="name" placeholder="Select Year" />
//                                 </div>
//                             </div>
//                             <div className="col-8 md:col-4">
//                                 <div className="p-inputgroup">
//                                     <Dropdown value={selectedMonth} options={months} onChange={onMonthChange} optionLabel="name" placeholder="Select Month" />
//                                 </div>
//                             </div>
//                             <div className="col-8 md:col-4">
//                                 <div className="p-inputgroup">
//                                     <Dropdown value={selectedTeeVo} options={teeVoList} onChange={onTeeVoChange} optionLabel="book_language" placeholder="Select Language" />
//                                     <Button icon="pi pi-download" rounded aria-label="Filter" onClick={onDownloadTeeVoClick} />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="flex align-items-start">
//                 <Button rounded text raised severity="warning" className="flex align-items-center" aria-label="Notification" onClick={handleHomePageClick}
//                     style={{ width: '200px', justifyContent: 'center' }}>
//                     Return to homepage
//                 </Button>
//                 <Button rounded text raised severity="warning" className="flex align-items-center ml-2" aria-label="User" onClick={handleSignOutClick}
//                     style={{ width: '200px', justifyContent: 'center' }}>
//                     Logout
//                 </Button>
//             </div>
//         </>
//     );
// };
