import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Link, useNavigate } from 'react-router-dom';
import { countries } from '../_data/Countries';
import { useUserActions } from '../_actions/user.actions';
import { register } from '../_services';
import { useMutation } from 'react-query';

export const Signup = () => {
    const msgError = useRef(null);
    const userActions = useUserActions();
    const navigate = useNavigate();

    const [churches, setChurches] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [isMember, setIsMember] = useState(true);
    const [checked, setChecked] = useState(true);

    const { mutateAsync: registerUser, isLoading: loading } = useMutation(register, {
        onError: (res) => {
            setShowMessage(false);
            console.error(res.response.data.message);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        },
        onSuccess: (data) => {
            setFormData(data);
            setShowMessage(true);
        },
    });

    const loadChurches = useCallback(() => {
        userActions.getChurches()
            .then((res) => {
                setChurches(res);
            })
            .catch((error) => {
                msgError.current.show([{ severity: 'error', detail: error.message, sticky: false, life: 5000 }]);
            });
    }, [userActions]);

    useEffect(() => {
        loadChurches();
    }, []);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: '',
            confirmPassword: '',
            church: '',
            country: '',
            isMember: true,
            acceptTerms: false,
        },
        validate: (data) => {
            let errors = {};

            if (!data.fullName) {
                errors.lastName = 'Full Name is required.';
            }

            if (checked && !data.church) {
                errors.church = 'Church is required.';
            }

            if (!data.email) {
                errors.email = 'Email is required.';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. E.g. example@email.com';
            }

            if (!data.password) {
                errors.password = 'Password is required.';
            }

            if (!data.confirmPassword) {
                errors.confirmPassword = 'Confirm Password is required.';
            } else if (data.password !== data.confirmPassword) {
                errors.confirmPassword = 'Passwords must match.';
            }

            if (!data.acceptTerms) {
                errors.acceptTerms = 'You need to agree to the terms and conditions.';
            }

            return errors;
        },
        onSubmit: async (data) => {
            const _data = {
                ...data,
                title: data.title?.name || '',
                country: data.country?.country || '',
                church: data.church?.name || '',
                isMember: isMember,
            };
            await createUser(_data);
        },
    });

    const createUser = async (data) => {
        setFormData(data);

        console.log("==========  SIGN UP   ===============");
        console.log(data);
        console.log("==========  SIGN UP   ===============");

        await registerUser(data);
    };

    const onDialogClose = () => {
        setShowMessage(false);
        formik.resetForm();
        navigate('/login');
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dialogFooter = (
        <div className="flex justify-content-center">
            <Button label="OK" className="p-button-text" autoFocus onClick={onDialogClose} />
        </div>
    );

    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </>
    );

    const onCheckChanged = useCallback((e) => {
        setChecked(e.checked);
        setIsMember(e.checked);
    }, []);

    return (


        <div className="ps-lg-5">
            <div className="card card-lg">
                <div className="card-body">
                    <Dialog
                        visible={showMessage}
                        onHide={() => setShowMessage(false)}
                        position="top"
                        footer={dialogFooter}
                        showHeader={false}
                        breakpoints={{ '960px': '80vw' }}
                        style={{ width: '30vw' }}
                    >
                        <div className="flex align-items-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>Registration Successful!</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                Your account is registered under name <b>{formData.lastName} {formData.fullName}</b>,
                                Please check <b>{formData.email} </b> for verification instructions
                            </p>
                        </div>
                    </Dialog>
                    <div>

                        <form onSubmit={formik.handleSubmit} className="p-fluid">
                            <Messages ref={msgError} />
                            <div className="field">
                                <span className="p-float-label">
                                    <InputText
                                        id="fullName"
                                        name="fullName"
                                        value={formik.values.fullName}
                                        onChange={formik.handleChange}
                                        autoFocus
                                        className={classNames({ 'p-invalid': isFormFieldValid('fullName') })}
                                    />
                                    <label htmlFor="fullName" className={classNames({ 'p-error': isFormFieldValid('fullName') })}>
                                        Full Name*
                                    </label>
                                </span>
                                {getFormErrorMessage('fullName')}
                            </div>

                            <div className="field">
                                <span className="p-float-label">
                                    <InputText
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                                    />
                                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>
                                        Email*
                                    </label>
                                </span>
                                {getFormErrorMessage('email')}
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <Password
                                        id="password"
                                        name="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        toggleMask
                                        className={classNames({ 'p-invalid': isFormFieldValid('password') })}
                                        header={passwordHeader}
                                        footer={passwordFooter}
                                    />
                                    <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>
                                        Password*
                                    </label>
                                </span>
                                {getFormErrorMessage('password')}
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <Password
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        toggleMask
                                        className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })}
                                    />
                                    <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>
                                        Confirm Password*
                                    </label>
                                </span>
                                {getFormErrorMessage('confirmPassword')}
                            </div>
                            <div className="field-checkbox">
                                <Checkbox
                                    inputId="isMember"
                                    name="isMember"
                                    checked={checked}
                                    onChange={onCheckChanged}
                                />
                                <label htmlFor="isMember">Are you a member?</label>
                            </div>
                            {checked && (
                                <div className="field">
                                    <span className="p-float-label">
                                        <Dropdown
                                            id="church"
                                            name="church"
                                            value={formik.values.church}
                                            options={churches}
                                            onChange={formik.handleChange}
                                            optionLabel="name"
                                            placeholder="Select a Church"
                                            className={classNames({ 'p-invalid': isFormFieldValid('church') })}
                                        />
                                        <label htmlFor="church" className={classNames({ 'p-error': isFormFieldValid('church') })}>
                                            Church
                                        </label>
                                    </span>
                                    {getFormErrorMessage('church')}
                                </div>
                            )}
                            <div className="field">
                                <span className="p-float-label">
                                    <Dropdown
                                        id="country"
                                        name="country"
                                        value={formik.values.country}
                                        options={countries}
                                        onChange={formik.handleChange}
                                        optionLabel="country"
                                        placeholder="Select a Country"
                                    />
                                    <label htmlFor="country">Country</label>
                                </span>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox
                                    inputId="acceptTerms"
                                    name="acceptTerms"
                                    checked={formik.values.acceptTerms}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('acceptTerms') })}
                                />
                                <label htmlFor="acceptTerms" className={classNames({ 'p-error': isFormFieldValid('acceptTerms') })}>
                                    I agree to the terms and conditions*
                                </label>
                            </div>
                            <Button type="submit" label="Sign Up" className="mt-2" loading={loading} />
                        </form>

                    </div>
                </div>
            </div>
        </div>




    );
};