import axios from 'axios';

const baseUrl = "/api";

const headers = {
    "content-type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
};


export const getFilesToDownload = async (language, book) => {

    const response = await axios.get(`${baseUrl}/transaction/getBookByName?language=${language}&name=${book.name}`);

    return response;
};



export const searchDownloadCount = async (book, start, end) => {

    return await axios({
        url: `${baseUrl}/transaction/getcountDownloadByDateRange?book=${book}&start=${start}&end=${end}`,
        method: 'get',
        timeout: 8000,
        headers: headers
    });
};


export const getChurches = async () => {

    return await axios.get(`${baseUrl}/church/getAll`).then(res => res.data);

};

export const createChurch = async (param) => {
    return await axios.post(`${baseUrl}/church/create/`, param).then(res => res.data);
};


export const deleteChurch = async (id) => {
    return await axios.delete(`${baseUrl}/church/delete/${id}`).then(res => res.data);
};

export const getUsers = async () => {
    return await axios.get(`${baseUrl}/accounts/getAll`).then(res => res.data);
};

export const getUser = async ({ queryKey }) => {

    const [id] = queryKey;

    return await axios.get(`${baseUrl}/accounts/getById/${id}`).then(res => res.data);
};


export const getLanguages = async () => {

    return await axios.get(`${baseUrl}/languages/getAll`).then(res => res.data);

};

export const createLanguage = async (param) => {
    return await axios.post(`${baseUrl}/languages/create/`, param).then(res => res.data);
};


export const deleteLanguage = async (id) => {



    return await axios.delete(`${baseUrl}/languages/${id}`).then(res => res.data);
};

export const updateLanguage = async (param) => {

    const headers = {
        'Authorization': 'Bearer ' + param.token,
        'Content-Type': 'application/json'
    };

    axios.put(`${baseUrl}/languages/${param.id}`, param, { headers })
        .then(res => res.data)
        .catch(err => console.error(err));

};
//========================== END AXIOS ==============================

export const getAllFiles = async () => {
    const response = await fetch(`${baseUrl}/files/getAllFiles`);

    if (!response.ok) {
        throw new Error("Something went wrong.");
    }
    return response.json();
};

export const deleteFile = async (id) => {

    await fetch(`${baseUrl}/files/delete/${id}`, { method: "GET" });
}

export const forgotPassword = async (email) => {
    return await axios.post(`${baseUrl}/accounts/forgot-password`, { email });
}


export const register = async (params) => {


    return await axios.post(`${baseUrl}/accounts/register`, {
        fullName: params.fullName,
        email: params.email,
        password: params.password,
        confirmPassword: params.confirmPassword,
        church: params.church,
        country: params.country,
        isMember: params.isMember,
        acceptTerms: params.acceptTerms
    });
}




export const deleteChurchccc = async (id) => {
    return await axios({
        url: `${baseUrl}/church/delete/${id}`,
        method: 'delete',
        timeout: 8000,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.data)
        .catch(err => console.error(err));
};

export const createChurchhhh = async (param) => {


    const headers = {
        'Authorization': 'Bearer ' + param.token,
        'Content-Type': 'application/json'
    };

    console.log(headers);

    axios.post(`${baseUrl}/church/create/`, param, { headers })
        .then(res => res.data)
        .catch(err => console.error(err));
};




export const updateChurch = async (param) => {


    const headers = {
        'Authorization': 'Bearer ' + param.token,
        'Content-Type': 'application/json'
    };



    axios.put(`${baseUrl}/church/update/${param.id}`, param, { headers })
        .then(res => res.data)
        .catch(err => console.error(err));

};


export const getUserssss = async () => {
    const response = await fetch(`${baseUrl}/account/getAll`);

    if (!response.ok) {
        throw new Error("Something went wrong.");
    }

    return response.json();
};


export const updateUser = async ({ id, ...data }) => {
    const response = await fetch(
        `${baseUrl}/account/${id}`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }
    );

    if (!response.ok) {
        throw new Error(response.json().message);
    }

    return response.json();
};

export const registerUser = async ({ ...data }) => {
    const response = await fetch(
        `${baseUrl}/account/register`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }
    );

    if (!response.ok) {
        throw new Error(response.json().message);
    }

    return response.json();
};


export const removeUser = async (id) => {
    const response = await fetch(
        `${baseUrl}/account/${id}`,
        {
            method: "DELETE"
        }
    );

    if (!response.ok) {
        throw new Error(response.json().message);
    }

    return true;
};