// import React from 'react';

// export const AppFooter = (props) => {

//     return (
//         <div className="layout-footer">
//             <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" />
//             by
//             <span className="font-medium ml-2">Teevo2go</span>
//         </div>
//     );
// }
import React from 'react';

export const AppFooter = (props) => {
    return (
        <div className="layout-footer fixed bottom-0 w-full flex justify-center items-center p-4 bg-gray-800 text-white">
            <img
                src={props.layoutColorMode === 'light'
                    ? 'assets/layout/images/logo-dark.svg'
                    : 'assets/layout/images/logo-white.svg'}
                alt="Logo"
                height="20"
                className="mr-2"
            />
            <span className="font-medium ml-2">by Teevo2go</span>
        </div>
    );
}
