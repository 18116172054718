import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Toolbar } from 'primereact/toolbar';


import { register, getChurches, getUser } from '../../_services';
import { countries } from '../../_data/Countries';

function User() {
    const msgError = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [setFormData] = useState({});

    const [submitted, setSubmitted] = useState(false);
    const [products, setProducts] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const { id } = useParams();

   // console.log('*************PROPS***************');
   // console.log({ id });
   // console.log('*************PROPS***************');

    const emptyUser = {
        title: 'Mr',
        lastName: '',
        firstName: '',
        email: '',
        password: '',
        confirmPassword: '',
        church: '',
        country: '',
        address: '',
        isMember: true,
        acceptTerms: false,
    };

    const [user, setUser] = useState(emptyUser);

    const titles = [
        { name: 'Pastor' },
        { name: 'Mr' },
        { name: 'Mrs' },
        { name: 'Miss' },
        { name: 'Sister' },
        { name: 'Brother' },
    ];

    const roles = [
        { name: 'Super Admin' },
        { name: 'Admin' },
        { name: 'User' },
    ];

    const { } = useQuery(['user', id], getUser, {
        onError: (res) => {
            setIsLoading(false);
            console.log(res.response.data.message);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        },
        onSuccess: (data) => {
            setIsLoading(false);
          //  console.log(data);
            setUser(data);

            const newlanguage = {};
            //  const newTitle = {};

            newlanguage.country = data.country;

            //  setSelectedTitle(newTitle);
            setSelectedCountry(newlanguage);


            const _user = { ...user };
            _user.title = data.title;

            setUser(_user);


        },
    });

    //
    const saveUser = () => {
        setSubmitted(true);

        console.log(user);

        if (user.firstName.trim()) {
            const _products = [...products];
            const _user = { ...user };
            if (user.id) {
                const index = findIndexById(user.id);

                _products[index] = _user;
                //  toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
            } else {
                _user.image = 'user-placeholder.svg';
                _products.push(_user);
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            }

            setProducts(_products);
            // setProductDialog(false);
            setUser(emptyUser);
        }
    };

    const deleteUser = () => {
        const _products = products.filter((val) => val.id !== user.id);
        setProducts(_products);
        //  setDeleteProductDialog(false);
        setUser(emptyUser);
        //  toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    };

    const onChurchChange = (e) => {
        const _user = { ...user };
        _user.church = e.value;
        setUser(_user);
    };

    const onCountryChange = (e) => {
        const _user = { ...user };
        _user.country = e.value;
        setUser(_user);
    };

    const onTitleChange = (e) => {
        const val = (e.target && e.target.value) || '';
        const _user = { ...user };
        _user.title = val;


        console.log('e.value');
        console.log(e.value);


        setUser(_user);

    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        const _user = { ...user };
        _user[`${name}`] = val;


        console.log('e.value');
        console.log(e.value);


        setUser(_user);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };


    const { data: churches } = useQuery('churches', getChurches, {
        onError: (res) => {
            setIsLoading(false);
            console.log(res.response.data.message);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        },
        onSuccess: (data) => {
            setIsLoading(false);
        },
    });
    const { mutateAsync: registerUser } = useMutation(register, {
        onError: (res) => {
            setIsLoading(false);
            console.log(res.response.data.message);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        },
        onSuccess: (data) => {
            alert();
            setFormData(data);
            setIsLoading(false);
        },
    });

    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </>
    );

    const rightToolbarTemplate = () => (
        <div className="my-2">
            <Button label="Save" icon="pi pi-plus" className="p-button-success mr-2" loading={isLoading} onClick={saveUser} />
            <Button label="Delete" icon="pi pi-trash" className="p-button-danger" loading={isLoading} onClick={deleteUser} />
        </div>

    );

    return (

        <div className="layout-main">

            <div className="grid">
                <div className="col-12 md:col-12">
                    <div className="card p-fluid">
                        <Messages ref={msgError} />

                        <h5>Create User</h5>

                        <div className="field grid">
                            <label htmlFor="Title" className="col-12 mb-2 md:col-2 md:mb-0">Title</label>
                            <div className="col-8 md:col-4">

                                <Dropdown id="title" name="title" value={user.title} onChange={(e) => onTitleChange(e)} options={titles} optionLabel="name" className={classNames({ 'p-invalid': submitted && !user.title })} />
                                {submitted && !user.title && <small className="p-invalid">title is required.</small>}

                            </div>

                        </div>

                        <div className="field grid">
                            <label htmlFor="firstName" className="col-12 mb-2 md:col-2 md:mb-0">First Name</label>
                            <div className="col-12 md:col-10">
                                <InputText id="firstName" placeholder="First Name" value={user.firstName} onChange={(e) => onInputChange(e, 'firstName')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.firstName })} />
                                {submitted && !user.firstName && <small className="p-invalid">First Name is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="lastName" className="col-12 mb-2 md:col-2 md:mb-0">Last Name</label>
                            <div className="col-12 md:col-10">
                                <InputText id="lastName" placeholder="Last Name" value={user.lastName} onChange={(e) => onInputChange(e, 'lastName')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.lastName })} />
                                {submitted && !user.lastName && <small className="p-invalid">Last Name is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="email" className="col-12 mb-2 md:col-2 md:mb-0">Email</label>
                            <div className="col-12 md:col-10">
                                <InputText id="email" placeholder="Email Address" value={user.email} type="email" onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />
                                {submitted && !user.email && <small className="p-invalid">Email is required.</small>}
                            </div>

                        </div>

                        <div className="field grid">
                            <label htmlFor="password" className="col-12 mb-2 md:col-2 md:mb-0">Password</label>
                            <div className="col-12 md:col-10">
                                <Password
                                    id="password"
                                    value={user.password}
                                    onChange={(e) => onInputChange(e, 'password')}
                                    toggleMask
                                    header={passwordHeader}
                                    footer={passwordFooter}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !user.password })}
                                />
                                {submitted && !user.password && <small className="p-invalid">Password is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="confirmPassword" className="col-12 mb-2 md:col-2 md:mb-0">Confirm Password</label>
                            <div className="col-12 md:col-10">
                                <Password
                                    id="confirmPassword"
                                    value={user.confirmPassword}
                                    onChange={(e) => onInputChange(e, 'confirmPassword')}
                                    toggleMask
                                    header={passwordHeader}
                                    footer={passwordFooter}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !user.confirmPassword })}
                                />
                                {submitted && !user.confirmPassword && <small className="p-invalid">Confirmed Password is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="address" className="col-12 mb-2 md:col-2 md:mb-0">Address</label>
                            <div className="col-12 md:col-10">
                                <InputText id="address" placeholder="Address" value={user.address} onChange={(e) => onInputChange(e, 'address')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.address })} />
                                {submitted && !user.address && <small className="p-invalid">First Name is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="address" className="col-12 mb-2 md:col-2 md:mb-0">Country</label>
                            <div className="col-12 md:col-10">
                                <Dropdown id="country" name="country" value={selectedCountry} onChange={(e) => onCountryChange(e, 'country')} options={countries} optionLabel="country" />
                                {submitted && !user.country && <small className="p-invalid">Country is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="isMember" className="col-12 mb-2 md:col-2 md:mb-0">Church</label>
                            <div className="col-12 md:col-10">
                                <Dropdown id="church" name="church" value={user.church} onChange={(e) => onChurchChange(e, 'church')} options={churches} optionLabel="name" />
                                {submitted && !user.church && <small className="p-invalid">Church is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="Title" className="col-12 mb-2 md:col-2 md:mb-0">Roles</label>
                            <div className="col-8 md:col-4">
                                <Dropdown id="role" name="role" value={user.role} onChange={(e) => onInputChange(e, 'role')} options={roles} optionLabel="name" className={classNames({ 'p-invalid': submitted && !user.role })} />
                                {submitted && !user.role && <small className="p-invalid">Role is required.</small>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Toolbar className="mb-4" right={rightToolbarTemplate} />

        </div>
    );
}

export { User };
